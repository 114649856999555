import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PageText = function ({children, title}) {
  return (
    <Wrapper>
      <h1>{title}</h1>
      {children}
    </Wrapper>
  );
};

PageText.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  display: block;
  margin: 0 auto;
  padding: 3rem 1rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  line-height: 1.5;
  font-family: Ubuntu, sans-serif;
  
  a {
    color: inherit;
  }
  
  h1, h2, h3, h4 {
    display: block;
    font-family: Ubuntu, sans-serif;
  }
`;

export default PageText;
